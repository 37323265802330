<header
    class="col-12 text-light header"
    [ngStyle]="{
        'background-color': (environment.api_url.includes('dreamaparts') && '#D8534E !important') || (environment.url.includes('lokalizacje') && '#000000')
    }"
>
    <app-header></app-header>
</header>
<div class="main-container">
    <router-outlet></router-outlet>
</div>
<footer class="footer_hotel">
    <div class="address_hotel">
        <div class="footer_image-wrapper">
            <a *ngIf="environment.url.includes('montownia')" href="https://montowniagdansk.pl/">
                <img src="assets/img/logo_footer.png" class="footer-image-logo" />
            </a>
            <img *ngIf="environment.url.includes('adela')" src="assets/img/adelaBlackLogo.png" alt="" class="footer-image-logo" />
            <img *ngIf="environment.url.includes('ren')" src="assets/img/logo.png" alt="" class="footer-image-logo" />
            <a *ngIf="environment.url.includes('n33')" href="https://destigohotels.com">
                <img src="assets/img/logoWhite.png" alt="" class="footer-image-logo" />
            </a>
        </div>
        <div class="addressFooter">{{ addressLine1 }}<br />{{ addressLine2 }}</div>
        <div class="social-wrapper">
            <a *ngIf="environment.facebook_url" [href]="environment.facebook_url" target="_blank"><i class="fab fa-facebook"></i></a>
            <a *ngIf="environment.instagram_url" [href]="environment.instagram_url" target="_blank"><i class="fab fa-instagram"></i></a>
            <a *ngIf="environment.linkedin_url" [href]="environment.linkedin_url" target="_blank"><i class="fab fa-linkedin"></i></a>
        </div>
    </div>
    <div class="app_info">
        <span>Booking Engine v.{{ appVersion }}<br />&copy; {{ copyrighttext }}</span>
    </div>
</footer>
