import { BrowserModule } from '@angular/platform-browser';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { NgModule } from '@angular/core';
import { MatMenuModule } from '@angular/material/menu';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { HTTP_INTERCEPTORS, HttpClient, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import {
  PERFECT_SCROLLBAR_CONFIG,
  PerfectScrollbarConfigInterface,
} from 'ngx-perfect-scrollbar';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './auth/login/login.component';
import { HeaderComponent } from './header/header.component';
import { AuthInterceptor } from './auth/auth.interceptor';
import { ServiceWorkerModule } from '@angular/service-worker';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { NgcCookieConsentConfig, NgcCookieConsentModule } from 'ngx-cookieconsent';
import { environment } from 'src/environments/environment';
import { ProblemComponent } from './problem/problem.component';
import { MatCardModule } from '@angular/material/card';
// import { BackButtonDisableModule } from 'angular-disable-browser-back-button';
import { MatDialogModule } from '@angular/material/dialog';

const cookieConfig: NgcCookieConsentConfig = {
    cookie: {
        domain: environment.url, // or 'your.domain.com' // it is mandatory to set a domain, for cookies to work properly (see https://goo.gl/S2Hy2A)
    },
    enabled: environment.cookiesOptionActive,
    position: 'bottom',
    theme: 'classic',
    palette: {
        popup: {
            background: '',
            text: '',
        },
        button: {
            background: '',
            text: '',
        },
    },
    type: 'info',
    content: {
        message: 'Ta strona korzysta z plików cookies i podobnych technologii do celów statystycznych aby świadczyć usługi na najwyższym poziomie. Dalsze korzystanie ze strony oznacza, że zgadzasz się na ich użycie.',
        dismiss: 'Zgadzam się',
        deny: 'Nie zgadzam się',
        link: '',
        href: environment.privacy_policy,
        policy: 'Polityka prywatności',
    },
};

export function createTranslateLoader(http: HttpClient) {
    return new TranslateHttpLoader(http);
}

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
    suppressScrollX: true,
};
@NgModule({ declarations: [AppComponent, LoginComponent, HeaderComponent, ProblemComponent],
    bootstrap: [AppComponent], imports: [BrowserModule,
        AppRoutingModule,
        BrowserAnimationsModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: createTranslateLoader, // exported factory function needed for AoT compilation
                deps: [HttpClient],
            },
        }),
        MatCardModule,
        MatMenuModule,
        MatIconModule,
        MatButtonModule,
        MatSnackBarModule,
        ServiceWorkerModule.register('ngsw-worker.js', {
            enabled: false,
        }),
        NgcCookieConsentModule.forRoot(cookieConfig),
        MatDialogModule], providers: [
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AuthInterceptor,
            multi: true,
        },
        {
            provide: PERFECT_SCROLLBAR_CONFIG,
            useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG,
        },
        { provide: MAT_DATE_LOCALE, useValue: 'pl-PL' },
        provideHttpClient(withInterceptorsFromDi()),
    ] })
export class AppModule {}
